import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import SupportLeftNav from "../../../components/TwoColumnLeftNav/SupportLeftNav";
import CatalogDownloadList from "../../../components/CatalogDownload/CatalogDownloadList";
import { LocaleContext } from "../../../components/layout";
import LocalizedLink from "../../../components/localizedLink";

const CatalogDownload = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { catalogDownload } = useTranslations();

	const CatalogList = [
		{
			alt: "SK総合カタログ 2024",
			image: "/asset/catalog/sogo2024-ja/hyo01.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/sogo2024-ja/sogo2024-all.pdf",
			title: "SK総合カタログ 2024",
			catalog_list_info_page: "A4 102ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
			catalog_list_sk_link: "※ページごとはこちら",
			sk_link: "/support/catalog-download/sk2024/",
		},
		{
			alt: "感染症・インフルエンザ対策2024",
			image: "/asset/catalog/2024influenza.png",
			link: "https://static.sksato.co.jp/leaflet/ja/2024influenza.pdf",
			title: "感染症・インフルエンザ対策2024",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "　",
			catalog_list_info_update: "2024年版感染症・インフルエンザ対策製品",
		},
		{
			alt: "pH計シリーズ",
			image: "/asset/catalog/PHseries.png",
			link: "https://static.sksato.co.jp/leaflet/ja/PHseries.pdf",
			title: "pH計シリーズ",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "　",
			catalog_list_info_update: "新製品SK-625PHを掲載",
		},
		{
			alt: "壁掛け用アタッチメント",
			image: "/asset/catalog/attachment.png",
			link: "https://static.sksato.co.jp/leaflet/ja/attachment.pdf",
			title: "壁掛け用アタッチメント",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "　",
			catalog_list_info_update: "各種製品を壁掛け設置にできるアタッチメント",
		},
		{
			alt: "熱中症予防キャンペーン",
			image: "/asset/catalog/2024_nettyu.png",
			link: "https://static.sksato.co.jp/leaflet/ja/nettyu2024.pdf",
			title: "熱中症予防キャンペーン2024",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "　",
			catalog_list_info_update: "2024年9月30日までお得なキャンペーンを実施中",
		},
		{
			alt: "USB受信ユニット SK-BTU1-R",
			image: "/asset/catalog/SK-BTU1-R.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-BTU1-R.pdf",
			title: "USB受信ユニット SK-BTU1-R",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "ペンタイプ防水型デジタル温度計シリーズ",
			image: "/asset/catalog/pc-thermometers.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/pc-thermometers.pdf",
			title: "ペンタイプ防水型デジタル温度計シリーズ",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "アスファルト／コンクリート養生用温度計",
			image: "/asset/catalog/asphalt3.png",
			link: "https://static.sksato.co.jp/leaflet/ja/asphalt2023.pdf",
			title: "アスファルト／コンクリート養生用温度計",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "デジタル温湿度計 PC-5410TRH",
			image: "/asset/catalog/PC-5410TRH.png",
			link: "https://static.sksato.co.jp/leaflet/ja/PC-5410TRH.pdf",
			title: "デジタル温湿度計 PC-5410TRH",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "食品用測定機器",
			image: "/asset/catalog/2023_shokuhin-2.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/shyokuhin2024.pdf",
			title: "食品用測定機器",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "デジタル温湿度計 SK-110TRH-B",
			image: "/asset/catalog/SK-110TRH-B.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-110TRH-B.pdf",
			title: "デジタル温湿度計 SK-110TRH-B",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "赤外線サーモカメラ SK-8500",
			image: "/asset/catalog/SK-8500.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-8500.pdf",
			title: "赤外線サーモカメラ SK-8500",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "デジタル最高最低温度計・冷蔵庫用デジタル温度計シリーズ",
			image: "/asset/catalog/PC-3310series.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/PC-3310series.pdf",
			title: "デジタル最高最低温度計・冷蔵庫用デジタル温度計シリーズ",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "記憶計 SK-L750シリーズ",
			image: "/asset/catalog/SK-L750.png",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-L750.pdf",
			title: "記憶計 SK-L750シリーズ",
			catalog_list_info_page: "A4 8ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "防水型無線温度ロガー SK-320BTシリーズ",
			image: "/asset/catalog/SK-320BT-C.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-320BT-C.pdf",
			title: "防水型無線温度ロガー SK-320BTシリーズ",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "快適ナビ CO2モニター SK-50CTH",
			image: "/asset/catalog/SK-50CTH.png",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-50CTH.pdf",
			title: "快適ナビ CO2モニター SK-50CTH",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "防水型デジタル温度計 SK-270WPシリーズ",
			image: "/asset/catalog/SK-270WPseries.png",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-270WPseries.pdf",
			title: "防水型デジタル温度計 SK-270WPシリーズ",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "冷蔵庫・冷凍庫、保管庫向け",
			image: "/asset/catalog/reizouko.png",
			link: "https://static.sksato.co.jp/leaflet/ja/reizouko.pdf",
			title: "冷蔵庫・冷凍庫、保管庫向け",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "防水放射温度計 SK-8950",
			image: "/asset/catalog/SK-8950.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-8950.pdf",
			title: "防水放射温度計 SK-8950",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "温度・湿度 JCSS校正のおしらせ",
			image: "/asset/catalog/JCSS.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/JCSS.pdf",
			title: "温度・湿度 JCSS校正のおしらせ",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "防水キッチンタイマー TM-36",
			image: "/asset/catalog/TM-36.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/TM-36.pdf",
			title: "防水キッチンタイマー TM-36",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "無線温度・温湿度ロガー SK-L700Rシリーズ",
			image: "/asset/catalog/SK-L700R.png",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-L700R.pdf",
			title: "無線温度・温湿度ロガー SK-L700Rシリーズ",
			catalog_list_info_page: "A4 8ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "精密型デジタル温度計 SK-810PT",
			image: "/asset/catalog/SK-810PT-02.png",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-810PT.pdf",
			title: "精密型デジタル温度計 SK-810PT",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "ノータッチタイマー「手洗い当番」TM-27",
			image: "/asset/catalog/TM-27.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/TM-27.pdf",
			title: "ノータッチタイマー「手洗い当番」TM-27",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "積算温度計 収穫どき SK-60AT-M",
			image: "/asset/catalog/SK-60AT-M.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-60AT-M.pdf",
			title: "積算温度計 収穫どき SK-60AT-M",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "快適ナビプラス PC-5500TRH",
			image: "/asset/catalog/PC-5500TRH-TM-29.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/PC-5500TRH.pdf",
			title: "快適ナビプラス PC-5500TRH",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "ノータッチタイマー TM-29",
			image: "/asset/catalog/TM-29.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/TM-29.pdf",
			title: "ノータッチタイマー TM-29",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "屋外設置用防水ケース",
			image: "/asset/catalog/bousui-case.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/bousui-case.pdf",
			title: "屋外設置用防水ケース",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "デジタル温湿度計 PC-7980GTI",
			image: "/asset/catalog/PC-7980GTI.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/PC-7980GTI.pdf",
			title: "デジタル温湿度計 PC-7980GTI",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "手持屈折計Rシリーズ",
			image: "/asset/catalog/0180.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-100R.pdf",
			title: "手持屈折計Rシリーズ",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "デジタル気圧計 SK-500B",
			image: "/asset/catalog/sk-500b.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-500B.pdf",
			title: "デジタル気圧計 SK-500B",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "最高最低無線温湿度計 SK-300R",
			image: "/asset/catalog/sk-300r.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-300R.pdf",
			title: "最高最低無線温湿度計 SK-300R",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "K熱電対センサシリーズ",
			image: "/asset/catalog/K-netudenntui.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/K-netudentui.pdf",
			title: "K熱電対センサシリーズ",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "4ch温度ロガー SK-L400T",
			image: "/asset/catalog/sk-l400t.jpg",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-L400T.pdf",
			title: "4ch温度ロガー SK-L400T",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "メモリ機能付き 防水型デジタル温度計 SK-1260",
			image: "/asset/catalog/SK-1260.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-1260.pdf",
			title: "メモリ機能付き 防水型デジタル温度計 SK-1260",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "非接触温度計シリーズ・結露チェカーSK-130ITH",
			image: "/asset/catalog/infrared_thermo_catalog.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/infrared_thermo_catalog.pdf",
			title: "非接触温度計シリーズ・結露チェカーSK-130ITH",
			catalog_list_info_page: "A4 8ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "食品用放射温度計 SK-8920",
			image: "/asset/catalog/sk8920.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-8920.pdf",
			title: "食品用放射温度計 SK-8920",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "赤外線放射温度計 SK-8940",
			image: "/asset/catalog/8266s_pdf.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-8940.pdf",
			title: "赤外線放射温度計 SK-8940",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "デジタル温湿度計 SK-140TRH",
			image: "/asset/catalog/sk-140TRHs.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-140TRH.pdf",
			title: "デジタル温湿度計 SK-140TRH",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},
		{
			alt: "デジタル温湿度計 SK-120TRH",
			image: "/asset/catalog/sk-120TRH2s.gif",
			link: "https://static.sksato.co.jp/leaflet/ja/SK-120TRH.pdf",
			title: "デジタル温湿度計 SK-120TRH",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "",
			catalog_list_info_update: "",
		},


	];

	const { locale } = React.useContext(LocaleContext);
	const CatalogDownloadJa = () => {
		return (
			<div className="content-container catalog-download catalog-download-image-border">
				<div>
					<p>
						製品カタログは、PDFファイルにて提供しております。
						<br />
					</p>
				</div>
				<div>
					<p className="important-text">
						※ダウンロードいただけるパンフレットは作成時点でのデータが元になっております。
						<br />
						価格・消費税率・仕様等は現行と異なることがありますのでご注意ください。
					</p>
				</div>
				<div>
					<ul className="catalog-ul flex wrap">
						{CatalogList.map((CatalogItem, index) => {
							return (
								<CatalogDownloadList
									key={index}
									image={CatalogItem.image}
									alt={CatalogItem.alt}
									link={CatalogItem.link}
									title={CatalogItem.title}
									catalog_list_info_page={CatalogItem.catalog_list_info_page}
									catalog_list_info_date={CatalogItem.catalog_list_info_date}
									catalog_list_info_update={
										CatalogItem.catalog_list_info_update
									}
									catalog_list_sk_link={CatalogItem.catalog_list_sk_link}
									sk_link={CatalogItem.sk_link}
								/>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};
	const CatalogDownloadEn = () => {
		return (
			<div className="content-container catalog-download-en">
				<p className="catalog-download-title bold">GENERAL CATALOG No. 621</p>
				<div className="catalog-download-contents">
					<img
						src={`/asset/catalog/general-catalog.jpg`}
						alt="General Catalog No.621"
						width="113"
						height="160"
					/>
					<p>
						Our product catalog in PDF format issued in 2021, amended in 2023, is available to download through the PDF logo in the right.
						<br />
						<LocalizedLink
							to="/support/catalog-download/sk/"
							className="skindex"
						>
							Click here
						</LocalizedLink>
						to download page by page.
					</p>

					<a href="https://static.sksato.co.jp/corporate/catalog/2023_GeneralCatalog.pdf">
						<img src={`/asset/PDF_32.png`} alt="pdf" width="32" height="32" />
						<span>15.0MB</span>
					</a>
				</div>
			</div>
		);
	};
	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>{catalogDownload}</h2>
					{locale === "ja" ? <CatalogDownloadJa /> : <CatalogDownloadEn />}
				</section>
			</article>
			<Footer />
		</>
	);
};

export default CatalogDownload;

